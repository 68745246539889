<template>
  <div class="v-container v-p-0">
    <div class="v-row v-justify-content-between v-align-items-end v-mb-md">
      <div
        class="v-col-auto v-mb-sm v-account-management--title v-title"
        v-html="translate('accountManagement.resetPass')"
      />
      <div class="v-col v-mb-sm v-d-flex v-justify-content-end">
        <arora-button
          class-name="v-btn-login-link v-btn v-btn-link v-btn-link-secondary v-no-underline v-d-flex"
          ignore-settings
          :label="translate('accountManagement.backToAuth')"
          @click="setLKModeLogin"
        >
          <span
            class="v-mr-xxs"
            v-html="translate('accountManagement.backToAuth')"
          />
          <icon-general-arrow-next />
        </arora-button>
      </div>

      <div class="v-col-12 v-mb-xxs v-account-management--subtitle v-d-flex v-flex-row">
        <span
          v-if="authVariant === AuthVariant.Telegram"
          class="v-text"
          v-html="translate('accountManagement.telegramHelpSecond')"
        />
        <span
          v-else-if="authVariant === AuthVariant.Flashcall"
          class="v-text"
          v-html="translate('accountManagement.flashcallHelpSecond')"
        />
        <span
          v-else
          class="v-text"
          v-html="translate('accountManagement.smsHelpSecond')"
        />
        <span
          class="v-phone-user"
          v-html="phoneBeautified(phone)"
        />
      </div>
    </div>
    <div class="v-row v-mb-md">
      <div class="v-col-12">
        <v-input
          class-name="v-arora-input"
          focus-on-mount
          required
          v-form-validator="{
            Form: validatorForm,
            Value: resetFields.code,
            Required: true,
            Validations: ['noSpace', 'length'],
            MaxLength: resetFields.codeMaxLength
          }"
          :label="authVariantCode(authVariant!)"
          v-model:text="resetFields.code"
        />
      </div>
      <div class="v-auth-type--help__light">
        <div class="v-icon">
          <icon-general-information-circle class="v-img-fluid v-w-100" />
        </div>
        <div
          v-if="authVariant === AuthVariant.Telegram"
          class="v-text"
        >
          <span
            class="v-mr-xxs"
            v-html="translate('accountManagement.telegramHelpForInputSecondLeft')"
          />
          <arora-button
            v-if="resetFields.externalAuthLink"
            id="auth-variant-telegram-link"
            class-name="v-btn v-btn-link v-link v-btn-link-secondary v-mr-xxs"
            ignore-settings
            :label="translate('accountManagement.telegramHelpForInputSecondLink')"
            @click="linkClick"
          />
          <span
            v-else
            v-html="translate('accountManagement.telegramHelpForInputSecondLink')"
          />
          <span v-html="translate('accountManagement.telegramHelpForInputSecondRight')" />
        </div>
        <div
          v-else-if="authVariant === AuthVariant.Flashcall"
          class="v-text"
          v-html="translate('accountManagement.flashcallHelpForInputSecond')"
        />
        <div
          v-else
          class="v-text v-d-flex v-align-items-center"
          v-html="translate('accountManagement.smsHelpForInputSecond')"
        />
      </div>
    </div>
    <div class="v-row v-mb-md">
      <div class="v-col-12">
        <lazy-common-captcha />
      </div>
    </div>
    <div class="v-row">
      <div class="v-col-12 v-col-md-6 v-mb-sm">
        <div v-html="translate('accountManagement.registerSuggestion')" />
        <arora-button
          class-name="v-btn-login-link v-btn v-btn-link"
          ignore-settings
          :label="translate('accountManagement.register')"
          @click="setLKModeRegister"
        />
      </div>
      <div class="v-col-12 v-col-md-6 v-mb-sm">
        <arora-button
          class-name="v-w-100"
          :disabled="resetDisabled || clicked"
          :label="translate('accountManagement.resetPass')"
          @click="async () => await resetPassConfirm()"
        />
      </div>
      <transition
        appear
        mode="out-in"
        name="fade"
      >
        <div
          v-if="loginError"
          class="v-col-12 v-text-center v-error-color"
          v-html="loginError"
        />
      </transition>
    </div>
  </div>
</template>

<script setup lang="ts">
import { AuthVariant, LKMode } from '~api/consts'

import type { ResetPayload } from '~types/accountStore'
import type { AccountManagementCommon } from '~types/props'

import { type ServerError, useCommon, useValidationStore, vFormValidator, VInput } from '@arora/common'
import { getActivePinia } from 'pinia'

const { authVariant, fields } = defineProps<
  AccountManagementCommon & {
    fields: ResetPayload
  }
>()
const emit = defineEmits(['update:mode', 'update:fields'])

const restaurantStore = useRestaurantStore()
const accountStore = useAccountStore()
const validationStore = useValidationStore(getActivePinia())

const { translate } = useI18nSanitized()
const { handleAuthError } = useAccount()
const { stringIsNullOrWhitespace } = useCommon()
const { phoneBeautified } = usePhoneNumber()

const { eventEmit } = useEmitter()
const appConfig = useAppConfig()

const clicked = ref<boolean>(false)
const loginError = ref<string | null>(null)

function setLKModeLogin(): void {
  emit('update:mode', LKMode.Login)
}

function setLKModeRegister(): void {
  emit('update:mode', LKMode.RegisterFirstStage)
}

const resetDisabled = computed<boolean>(() => stringIsNullOrWhitespace(resetFields.value.code))
const resetFields = computed<ResetPayload>({
  get() {
    return fields
  },
  set(value: ResetPayload) {
    emit('update:fields', value)
  }
})

async function handleErrorMessage(error: ServerError): Promise<void> {
  loginError.value = await handleAuthError(error)
}
const validatorForm = 'requestNewPass2'
async function resetPassConfirm(): Promise<void> {
  clicked.value = true
  if (!validationStore.formPassedCheck(validatorForm)) {
    clicked.value = false

    return
  }
  if (!resetFields.value.code) return //for enter keypress

  resetFields.value.country = appConfig.VueSettingsPreRun.SelectedLanguage

  resetFields.value = await restaurantStore.validateCaptcha(resetFields.value)
  const error = await accountStore.resetPassword(resetFields.value)

  await handleErrorMessage(error)
  clicked.value = false
  if (error.code === 0) reloadNuxtApp()
  else eventEmit('v-reset-captcha')
}
function linkClick(): void {
  setTimeout(() => window.open(resetFields.value.externalAuthLink ?? '/', '_blank'))
}
onMounted(() => {
  if (authVariant === AuthVariant.Telegram) linkClick()
})
</script>
