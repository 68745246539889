<template>
  <div class="v-container v-p-0">
    <div class="v-row v-justify-content-between v-align-items-end v-mb-md">
      <div
        class="v-col-auto v-mb-sm v-account-management--title v-title"
        v-html="translate('accountManagement.resetPass')"
      />
      <div
        v-if="onlyBySms"
        class="v-col-auto v-account-management--subtitle"
        v-html="translate('accountManagement.enterPhoneNumberHelper')"
      />
      <div class="v-col v-mb-sm v-d-flex v-justify-content-end">
        <arora-button
          class-name="v-btn-login-link v-btn v-btn-link v-btn-link-secondary v-no-underline v-d-flex"
          ignore-settings
          :label="translate('accountManagement.backToAuth')"
          @click="setLKModeLogin"
        >
          <span
            class="v-mr-xxs"
            v-html="translate('accountManagement.backToAuth')"
          />
          <icon-general-arrow-next />
        </arora-button>
      </div>
    </div>
    <div class="v-row v-mb-md">
      <div class="v-col-12">
        <common-phone-input
          focus-on-mount
          :label="translate('accountManagement.enterPhoneNumber')"
          :validator-form="validatorForm"
          v-model:phone="inputPhone"
        />
      </div>
    </div>
    <div class="v-d-flex v-flex-row v-row-auth-variants v-mb-md">
      <div
        v-if="
          authVariant === AuthVariant.Telegram ||
          authVariant === AuthVariant.Flashcall ||
          authVariant === AuthVariant.Sms
        "
        class="v-w-100 v-get-the-code"
        v-html="translate('accountManagement.getTheCode')"
      />
      <div
        v-for="variant in appConfig.RestaurantSettingsPreRun.AuthVariants"
        :key="`authVariant-${variant}`"
        :class="`v-col-auth-variant v-text-center v-auth-variants-${appConfig.RestaurantSettingsPreRun.AuthVariants.length}`"
      >
        <input
          :id="`auth-variant-${variant}`"
          v-form-validator="{
            Form: validatorForm,
            Value: authVariantModel,
            Required: true,
            Validations: []
          }"
          :value="variant"
          type="radio"
          v-model="authVariantModel"
        />
        <label
          class="v-btn v-btn-auth-variant v-align-items-center"
          :for="`auth-variant-${variant}`"
        >
          <span class="v-icon">
            <icon-social-telegram-no-indentation v-if="variant === AuthVariant.Telegram" />
            <icon-general-phone v-else-if="variant === AuthVariant.Flashcall" />
            <icon-general-message v-else-if="variant === AuthVariant.Sms" />
          </span>
          <span
            class="v-name"
            v-html="authVariantText(variant)"
          />
        </label>
      </div>
      <div class="v-auth-type--help__main">
        <div
          v-if="authVariant === AuthVariant.Telegram"
          class="v-text"
          v-html="translate('accountManagement.telegramHelp')"
        />
        <div
          v-else-if="authVariant === AuthVariant.Flashcall"
          class="v-text"
          v-html="translate('accountManagement.flashcallHelp')"
        />
        <div
          v-else-if="authVariant === AuthVariant.Sms"
          class="v-text"
          v-html="translate('accountManagement.smsHelp')"
        />
      </div>
    </div>
    <div class="v-row v-mb-md">
      <div class="v-col-12">
        <lazy-common-captcha />
      </div>
    </div>
    <div class="v-row v-mb-md v-personal-data">
      <div
        v-if="appConfig.VueSettingsPreRun.EnablePersonalDataWarning"
        class="v-col-12"
      >
        <span
          class="v-mr-xxs"
          v-html="translate('accountManagement.personalDataMessage')"
        />
        <arora-nuxt-link
          open-in-new-tab
          :href="appConfig.VueSettingsPreRun.Links.PersonalDataLink"
          :label="translate('accountManagement.personalDataMessageLink')"
        />
      </div>
      <div
        v-else
        class="v-col-12"
      >
        <span v-html="translate('accountManagement.agreementText')" />
        <arora-nuxt-link
          open-in-new-tab
          :href="appConfig.VueSettingsPreRun.Links.AgreementLink"
          :label="translate('accountManagement.agreementLink')"
        />
      </div>
    </div>
    <div class="v-row v-justify-content-between">
      <div class="v-col-12 v-col-md-auto v-mb-sm">
        <div v-html="translate('accountManagement.registerSuggestion')" />
        <arora-button
          class-name="v-btn-login-link v-btn v-btn-link"
          ignore-settings
          :label="translate('accountManagement.register')"
          @click="setLKModeRegister"
        />
      </div>

      <div class="v-col-12 v-col-md-auto v-mb-sm">
        <arora-button
          class-name="v-w-100"
          :disabled="
            verifyDisabled ||
            clicked ||
            (appConfig.RestaurantSettingsPreRun.AuthVariants.length > 1 && !authVariant)
          "
          :label="translate('accountManagement.resetPass')"
          @click="async () => await verifyMainContact()"
        />
      </div>

      <transition
        appear
        mode="out-in"
        name="fade"
      >
        <div
          v-if="loginError"
          class="v-col-12 v-text-center v-error-color"
          v-html="loginError"
        />
      </transition>
    </div>
  </div>
</template>

<script setup lang="ts">
import { AuthVariant, LKMode } from '~api/consts'

import type { ResetPayload } from '~types/accountStore'
import type { AccountManagementCommon } from '~types/props'

import { type ServerError, useValidationStore } from '@arora/common'
import { getActivePinia } from 'pinia'

const { fields, phone, authVariant } = defineProps<
  AccountManagementCommon & {
    fields: ResetPayload
  }
>()

const emit = defineEmits(['update:mode', 'update:phone', 'update:fields', 'update:authVariant'])

const restaurantStore = useRestaurantStore()
const accountStore = useAccountStore()
const validationStore = useValidationStore(getActivePinia())

const { handleAuthError } = useAccount()
const { phoneLength } = usePhoneNumber()
const { clean } = useI18nSanitized()

const appConfig = useAppConfig()

const loginError = ref<string | null>(null)

async function handleErrorMessage(error: ServerError): Promise<void> {
  loginError.value = await handleAuthError(error)
}

const inputPhone = computed<string>({
  get() {
    return phone
  },
  set(value) {
    emit('update:phone', clean(value ?? ''))
  }
})

const verifyDisabled = computed<boolean>(() => {
  return inputPhone.value?.length !== phoneLength.value
})
const clicked = ref<boolean>(false)

const { translate } = useI18nSanitized()
const { eventEmit } = useEmitter()

function setLKModeLogin(): void {
  emit('update:mode', LKMode.Login)
}

function setLKModeRegister(): void {
  emit('update:mode', LKMode.RegisterFirstStage)
}

const resetFields = computed<ResetPayload>({
  get() {
    return fields
  },
  set(value: ResetPayload) {
    emit('update:fields', value)
  }
})

const authVariantModel = computed<AuthVariant | null | undefined>({
  get() {
    return authVariant
  },
  set(value: AuthVariant | null | undefined) {
    emit('update:authVariant', value)
  }
})

const validatorForm = 'requestNewPass1'

async function verifyMainContact(): Promise<void> {
  clicked.value = true
  if (inputPhone.value?.length !== phoneLength.value) return //for enter keypress

  resetFields.value = await restaurantStore.validateCaptcha(resetFields.value)

  resetFields.value.country = appConfig.VueSettingsPreRun.SelectedLanguage
  resetFields.value.phone = inputPhone.value

  if (!validationStore.formPassedCheck(validatorForm)) {
    clicked.value = false

    return
  }

  const error = await accountStore.verifyMainContact(
    resetFields.value,
    appConfig.RestaurantSettingsPreRun.AuthVariants.length === 0 ? AuthVariant.Default : authVariant!
  )

  await handleErrorMessage(error)

  if (error.code === 0) {
    emit('update:mode', LKMode.RequestNewPassSecondStage)
    emit(
      'update:authVariant',
      appConfig.RestaurantSettingsPreRun.AuthVariants.length === 0 ? AuthVariant.Default : authVariant
    )
  } else {
    eventEmit('v-reset-captcha')
  }
  clicked.value = false
}
</script>
